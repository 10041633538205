<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Tables</div>
                    <div class="card-body">
                        <table class="table">
                            <thead>
                            <tr>
                                <th style="width:50%;">Name</th>
                                <th style="width:10%;">Date</th>
                                <th>Rate</th>
                                <th>Sales</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Penelope Thornton</td>
                                <td>23/06/2018</td>
                                <td>60%</td>
                                <td>639</td>
                                <td><a href="#"><fa icon="trash" /></a></td>
                            </tr>
                            <tr>
                                <td>Justine Myranda</td>
                                <td>15/05/2018</td>
                                <td>23%</td>
                                <td>235</td>
                                <td><a href="#"><fa icon="trash" /></a></td>
                            </tr>
                            <tr>
                                <td>Benji Harper</td>
                                <td>10/03/2018</td>
                                <td>79%</td>
                                <td>728</td>
                                <td><a href="#"><fa icon="trash" /></a></td>
                            </tr>
                            <tr>
                                <td>Sherwood Clifford</td>
                                <td>18/01/2018</td>
                                <td>18%</td>
                                <td>135</td>
                                <td><a href="#"><fa icon="trash" /></a></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Striped Tables</div>
                    <div class="card-body">
                        <table class="table table-striped table-borderless">
                            <thead>
                            <tr>
                                <th style="width:50%;">Name</th>
                                <th style="width:10%;">Date</th>
                                <th>Rate</th>
                                <th>Sales</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody class="border-left-0 border-right-0">
                            <tr>
                                <td>Penelope Thornton</td>
                                <td>23/06/2018</td>
                                <td>60%</td>
                                <td>639</td>
                                <td><a href="#"><fa icon="trash" /></a></td>
                            </tr>
                            <tr>
                                <td>Justine Myranda</td>
                                <td>15/05/2018</td>
                                <td>23%</td>
                                <td>235</td>
                                <td><a href="#"><fa icon="trash" /></a></td>
                            </tr>
                            <tr>
                                <td>Benji Harper</td>
                                <td>10/03/2018</td>
                                <td>79%</td>
                                <td>728</td>
                                <td><a href="#"><fa icon="trash" /></a></td>
                            </tr>
                            <tr>
                                <td>Sherwood Clifford</td>
                                <td>18/01/2018</td>
                                <td>18%</td>
                                <td>135</td>
                                <td><a href="#"><fa icon="trash" /></a></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Condensed Tables</div>
                    <div class="card-body">
                        <table class="table table-sm table-striped">
                            <thead>
                            <tr>
                                <th>Operating System</th>
                                <th>Users</th>
                                <th>Rebound</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Windows</td>
                                <td>1.580</td>
                                <td>20%</td>
                                <td><a href="#"><fa icon="cog" /></a></td>
                            </tr>
                            <tr>
                                <td>Mac OS</td>
                                <td>1.322</td>
                                <td>55%</td>
                                <td><a href="#"><fa icon="cog" /></a></td>
                            </tr>
                            <tr>
                                <td>Linux</td>
                                <td>850</td>
                                <td>45%</td>
                                <td><a href="#"><fa icon="cog" /></a></td>
                            </tr>
                            <tr>
                                <td>Android</td>
                                <td>560</td>
                                <td>70%</td>
                                <td><a href="#"><fa icon="cog" /></a></td>
                            </tr>
                            <tr>
                                <td>iOS</td>
                                <td>450</td>
                                <td>39%</td>
                                <td><a href="#"><fa icon="cog" /></a></td>
                            </tr>
                            <tr>
                                <td>Other</td>
                                <td>317</td>
                                <td>67%</td>
                                <td><a href="#"><fa icon="cog" /></a></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Hover</div>
                    <div class="card-body">
                        <table class="table table-striped table-hover">
                            <thead>
                            <tr>
                                <th style="width:37%;">User</th>
                                <th style="width:36%;">Commit</th>
                                <th>Date</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Penelope Thornton</td>
                                <td>Initial commit</td>
                                <td>Aug 6, 2018</td>
                                <td><a href="#"><fa icon="trash" /></a></td>
                            </tr>
                            <tr>
                                <td>Benji Harper</td>
                                <td>Main structure markup</td>
                                <td>Jul 28, 2018</td>
                                <td><a href="#"><fa icon="trash" /></a></td>
                            </tr>
                            <tr>
                                <td>Justine Myranda</td>
                                <td>Left sidebar adjusments</td>
                                <td>Jul 15, 2018</td>
                                <td><a href="#"><fa icon="trash" /></a></td>
                            </tr>
                            <tr>
                                <td>Sherwood Clifford</td>
                                <td>Topbar dropdown style</td>
                                <td>Jun 30, 2018</td>
                                <td><a href="#"><fa icon="trash" /></a></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Bordered Table</div>
                    <div class="card-body">
                        <table class="table table-sm table-hover table-bordered table-striped">
                            <thead>
                            <tr>
                                <th>Operating System</th>
                                <th>Users</th>
                                <th>Rebound</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Windows</td>
                                <td>1.580</td>
                                <td>20%</td>
                            </tr>
                            <tr>
                                <td>Mac OS</td>
                                <td>1.322</td>
                                <td>55%</td>
                            </tr>
                            <tr>
                                <td>Linux</td>
                                <td>850</td>
                                <td>45%</td>
                            </tr>
                            <tr>
                                <td>Android</td>
                                <td>560</td>
                                <td>70%</td>
                            </tr>
                            <tr>
                                <td>iOS</td>
                                <td>450</td>
                                <td>39%</td>
                            </tr>
                            <tr>
                                <td>Other</td>
                                <td>317</td>
                                <td>67%</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Contextual Classes</div>
                    <div class="card-body">
                        <table class="table table-striped table-borderless">
                            <thead>
                            <tr>
                                <th style="width:32%;">Class</th>
                                <th style="width:45%;">Commit</th>
                                <th>Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Penelope Thornton</td>
                                <td>Init</td>
                                <td>May 6, 2018</td>
                            </tr>
                            <tr class="table-primary">
                                <td>Benji Harper</td>
                                <td>Main structure markup</td>
                                <td>April 22, 2018</td>
                            </tr>
                            <tr>
                                <td>Justine Myranda</td>
                                <td>Left sidebar adjusments</td>
                                <td>April 15, 2018</td>
                            </tr>
                            <tr class="table-success">
                                <td>Sherwood Clifford</td>
                                <td>Topbar dropdown style</td>
                                <td>April 8, 2018</td>
                            </tr>
                            <tr>
                                <td>Kristopher Donny</td>
                                <td>Left sidebar adjusments</td>
                                <td>April 15, 2018</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Responsive Table</div>
                    <div class="card-body">
                        <div class="table-responsive noSwipe">
                            <table class="table table-striped table-hover">
                                <thead>
                                <tr>
                                    <th style="width:4%;">
                                        <div class="custom-control custom-control-sm custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" id="check1">
                                            <label class="custom-control-label" for="check1"></label>
                                        </div>
                                    </th>
                                    <th style="width:20%;">User</th>
                                    <th style="width:17%;">Last Commit</th>
                                    <th style="width:15%;">Milestone</th>
                                    <th style="width:10%;">Branch</th>
                                    <th style="width:10%;">Date</th>
                                    <th style="width:10%;"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <div class="custom-control custom-control-sm custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" id="check2">
                                            <label class="custom-control-label" for="check2"></label>
                                        </div>
                                    </td>
                                    <td><span>Penelope Thornton</span><span class="ml-1">Developer</span></td>
                                    <td><span>Initial commit</span><span class="ml-1">Bootstrap Admin</span></td>
                                    <td><span>8 / 15</span><span>v1.2.0</span>
                                        <div class="progress mt-1">
                                            <div class="progress-bar progress-bar-primary" style="width: 45%;"></div>
                                        </div>
                                    </td>
                                    <td><span>master</span><span class="ml-1">63e8ec3</span></td>
                                    <td><span>May 6, 2018</span><span class="ml-1">8:30</span></td>
                                    <td class="text-right">
                                        <div class="btn-group btn-hspace">
                                            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">Open <span class="icon-dropdown mdi mdi-chevron-down"></span></button>
                                            <div class="dropdown-menu" role="menu"><a class="dropdown-item" href="#">Action</a><a class="dropdown-item" href="#">Another action</a><a class="dropdown-item" href="#">Something else here</a>
                                                <div class="dropdown-divider"></div><a class="dropdown-item" href="#">Separated link</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="primary">
                                    <td>
                                        <div class="custom-control custom-control-sm custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" id="check3">
                                            <label class="custom-control-label" for="check3"></label>
                                        </div>
                                    </td>
                                    <td><span>Benji Harper</span><span class="ml-1">Designer</span></td>
                                    <td> <span>Main structure markup</span><span class="ml-1">CLI Connector</span></td>
                                    <td><span>22 / 30</span><span>v1.1.5</span>
                                        <div class="progress mt-1">
                                            <div class="progress-bar progress-bar-primary" style="width: 75%;"></div>
                                        </div>
                                    </td>
                                    <td><span>develop</span><span class="ml-1">4cc1bc2</span></td>
                                    <td><span>April 22, 2018</span><span class="ml-1">14:45</span></td>
                                    <td class="text-right">
                                        <div class="btn-group btn-hspace">
                                            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">Open <span class="icon-dropdown mdi mdi-chevron-down"></span></button>
                                            <div class="dropdown-menu" role="menu"><a class="dropdown-item" href="#">Action</a><a class="dropdown-item" href="#">Another action</a><a class="dropdown-item" href="#">Something else here</a>
                                                <div class="dropdown-divider"></div><a class="dropdown-item" href="#">Separated link</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="custom-control custom-control-sm custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" id="check4">
                                            <label class="custom-control-label" for="check4"></label>
                                        </div>
                                    </td>
                                    <td><span>Justine Myranda</span><span class="ml-1">Designer</span></td>
                                    <td> <span>Left sidebar adjusments</span><span class="ml-1">Back-end Manager</span></td>
                                    <td><span>10 / 30</span><span>v1.1.3</span>
                                        <div class="progress mt-1">
                                            <div class="progress-bar progress-bar-primary" style="width: 33%;"></div>
                                        </div>
                                    </td>
                                    <td><span>develop</span><span class="ml-1">5477993</span></td>
                                    <td><span>April 15, 2018</span><span class="ml-1">10:00</span></td>
                                    <td class="text-right">
                                        <div class="btn-group btn-hspace">
                                            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">Open <span class="icon-dropdown mdi mdi-chevron-down"></span></button>
                                            <div class="dropdown-menu" role="menu"><a class="dropdown-item" href="#">Action</a><a class="dropdown-item" href="#">Another action</a><a class="dropdown-item" href="#">Something else here</a>
                                                <div class="dropdown-divider"></div><a class="dropdown-item" href="#">Separated link</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="custom-control custom-control-sm custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" id="check5">
                                            <label class="custom-control-label" for="check5"></label>
                                        </div>
                                    </td>
                                    <td><span>Sherwood Clifford</span><span class="ml-1">Developer</span></td>
                                    <td><span>Topbar dropdown style</span><span class="ml-1">Bootstrap Admin</span></td>
                                    <td><span>25 / 40</span><span>v1.0.4</span>
                                        <div class="progress mt-1">
                                            <div class="progress-bar progress-bar-primary" style="width: 55%;"></div>
                                        </div>
                                    </td>
                                    <td><span>master</span><span class="ml-1">8cb98ec</span></td>
                                    <td><span>April 8, 2018</span><span class="ml-1">17:24</span></td>
                                    <td class="text-right">
                                        <div class="btn-group btn-hspace">
                                            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">Open <span class="icon-dropdown mdi mdi-chevron-down"></span></button>
                                            <div class="dropdown-menu" role="menu"><a class="dropdown-item" href="#">Action</a><a class="dropdown-item" href="#">Another action</a><a class="dropdown-item" href="#">Something else here</a>
                                                <div class="dropdown-divider"></div><a class="dropdown-item" href="#">Separated link</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="primary">
                                    <td>
                                        <div class="custom-control custom-control-sm custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" id="check6">
                                            <label class="custom-control-label" for="check6"></label>
                                        </div>
                                    </td>
                                    <td><span>Kristopher Donny</span><span class="ml-1">Designer</span></td>
                                    <td> <span>Right sidebar adjusments</span><span class="ml-1">CLI Connector</span></td>
                                    <td><span>38 / 40</span><span>v1.0.1</span>
                                        <div class="progress mt-1">
                                            <div class="progress-bar progress-bar-primary" style="width: 98%;"></div>
                                        </div>
                                    </td>
                                    <td><span>master</span><span class="ml-1">65bc2da</span></td>
                                    <td><span>Mars 18, 2018</span><span class="ml-1">13:02</span></td>
                                    <td class="text-right">
                                        <div class="btn-group btn-hspace">
                                            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">Open <span class="icon-dropdown mdi mdi-chevron-down"></span></button>
                                            <div class="dropdown-menu" role="menu"><a class="dropdown-item" href="#">Action</a><a class="dropdown-item" href="#">Another action</a><a class="dropdown-item" href="#">Something else here</a>
                                                <div class="dropdown-divider"></div><a class="dropdown-item" href="#">Separated link</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Table',
    }
</script>
